import React from 'react';
import PropTypes from 'prop-types';
// import { Container } from './box.css';

const Box = ({ className, children }) => (
  <section className={className}>{children}</section>
);

Box.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Box;
