import React from 'react'
import PropTypes from 'prop-types'
import 'react-image-gallery/styles/css/image-gallery.css'
import classNames from 'classnames'

const Map = React.lazy(() => import('./map'))
const Form = React.lazy(() => import('./form'))
const BukazuPortal = React.lazy(() => import('./BukazuPortal'))
const ImageSlider = React.lazy(() => import('components/imageSlider'))
const ImageGallery = React.lazy(() => import('react-image-gallery'))

const isSSR = typeof window === 'undefined'

const PageBlocks = ({ blocks }) => {
  return (
    <React.Fragment>
      {blocks.map(function(block, index) {
        return <Block block={block} key={index} />
      })}
    </React.Fragment>
  )
}

const Block = ({ block }) => {
  const bo = block.options
  let val
  let classes = ''
  Object.entries(bo.classes).forEach(element => {
    classes += ''
    classes += element[1]
  })

  classes = classNames(classes, bo.classes.width, bo.className)

  if (bo.type === 'text') {
    val = (
      <div
        style={bo.style}
        className={classes}
        dangerouslySetInnerHTML={{ __html: bo.content }}
      />
    )
  } else if (bo.type === 'image') {
    val = (
      <div style={bo.style} className={classes}>
        <img
          src={
            bo.content[0]
              ? 'https://cms.burobork.nl/images/' + bo.content[0].reference
              : null
          }
          alt={bo.content[0] ? bo.content[0].title : null}
        />
      </div>
    )
  } else if (bo.type === 'image_slider') {
    val = (
      <>
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <ImageSlider
              style={bo.style}
              className={classes}
              slides={bo.content}
            />
          </React.Suspense>
        )}
      </>
    )
  } else if (bo.type === 'iframe') {
    val = (
      <div style={bo.style} className={classes}>
        <iframe
          src={bo.content}
          width={bo.iframeWidth}
          height={bo.iframeHeight}
          title={bo.content}
        />
      </div>
    )
  } else if (bo.type === 'embed') {
    val = (
      <div style={bo.style} className={classes}>
        <embed
          src={'https://cdn.burobork.nl/images/' + bo.content[0].reference}
          width={bo.embedWidth}
          height={bo.embedHeight}
          type={bo.embedType}
        />
      </div>
    )
  } else if (bo.type === 'map') {
    val = (
      <div style={bo.style} className={classes}>
        <>
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <Map
                latitude={Number(bo.location.lat)}
                longitude={Number(bo.location.lng)}
                isMarkerShown={true}
              />
            </React.Suspense>
          )}
        </>
      </div>
    )
  } else if (bo.type === 'bukazu_portal') {
    val = (
      <>
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <BukazuPortal bo={bo} classes={classes} />
          </React.Suspense>
        )}
      </>
    )
  } else if (bo.type === 'form') {
    val = (
      <>
        {!isSSR && (
          <div style={bo.style} className={classes}>
            <React.Suspense fallback={<div />}>
              <Form form={bo.content} />
            </React.Suspense>
          </div>
        )}
      </>
    )
  } else if (bo.type === 'imageGallery') {
    const images = bo.content.map(img => {
      if (img.reference) {
        return {
          original: 'https://cms.burobork.nl/images/' + img.reference,
          thumbnail: 'https://cms.burobork.nl/images/' + img.reference,
        }
      } else {
        return {
          original: 'https://' + img.preview,
          thumbnail: 'https://' + img.preview,
        }
      }
    })
    val = (
      <div style={{ ...bo.style, overflow: 'hidden' }} className={classes}>
        <>
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <ImageGallery
                items={images}
                showPlayButton={false} // thumbnailPosition="right"
                renderFullscreenButton={(onClick, isFullscreen) => (
                  <button
                    type="button"
                    className={`image-gallery-fullscreen-button${
                      isFullscreen ? ' active' : ''
                    }`}
                    onClick={onClick}
                  />
                )}
              />
            </React.Suspense>
          )}
        </>
      </div>
    )
  } else {
    val = (
      <div
        style={bo.style}
        className={classes}
        dangerouslySetInnerHTML={{ __html: bo.content }}
      />
    )
  }
  return val
}

PageBlocks.propTypes = {
  blocks: PropTypes.array.isRequired,
}

export default PageBlocks
