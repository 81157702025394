import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import Layout from 'components/layout';
import Head from 'components/head';
import Box from 'components/box';
import PageBlocks from 'components/pageBlocks';

import 'style/pageBlocks.scss'

const PageTemplate = ({ data }) => {
  const fields = JSON.parse(data.buroBorkPage.field);
  return (
    <Layout locale={fields.locale}>
      <Head
        pageTitle={fields.seo_title ? fields.seo_title : fields.title}
        locale={fields.locale}
        pageDescription={fields.meta_description}
      />
      {fields.page_rows.map((row, index) => {
        let classes = 'page_row'
        if (row.options && row.options.classes) {
          classes = classNames(classes, Object.values(row.options.classes))
        }
        return (
          <Box key={index} className={classes}>
            <PageBlocks blocks={row.page_blocks} />
          </Box>
        )
      })}
    </Layout>
  )
};

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query BuroBorkPagebySlug($id: String!) {
    buroBorkPage(id: { eq: $id }) {
      id
      field
    }
  }
`;

export default PageTemplate;
